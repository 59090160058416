<template>
<div>
    <div class="row">
      <div class="col-lg-3 col-xs-6">
      </div>
    </div>
    <div class="row">
      <div v-if="user.cus" class="col-lg-3">
        <div class="box box-widget">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <!-- <div class="box-header">

            <h3 class="box-title">Informace o autoservisu</h3>

            </div> -->
            <div class="box-footer no-padding">
                <ul class="nav nav-stacked">
                    <li><a href="#">Servis<span class="pull-right">{{group.station.company_name}}</span></a></li>
                    <li v-if="group.station.email"><a :href="'mailto:' + group.station.email">Email <a href="#" class="pull-right">{{group.station.email}}</a></a></li>
                    <li v-if="group.station.phone"><a :href="'tel:' + user.phone">Telefon<a href="#" class="pull-right">{{group.station.phone}}</a></a></li>
                </ul>
            </div>
        </div>
      </div>
      <div class="col-lg-3 col-xs-6">
        <!-- small box -->
        <div class="small-box bg-aqua">
          <div class="inner">
            <h3>{{stats.plan_issue_num}}</h3>

            <p>Plánovaných úkonů</p>
          </div>
          <div class="icon">
            <i class="ion ion-ios-clipboard"></i>
          </div>
          <a @click="passToLink" href="/#/issues?showByStage=1&threshold=7" class="small-box-footer">Seznam úkonů  <i class="fa fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <div :class="{'col-lg-3' : true, 'col-xs-6': true, 'hidden-xs': user.cus}">
        <!-- small box -->
        <div class="small-box bg-yellow">
          <div class="inner">
            <h3>{{stats.open_issue_num}}</h3>

            <p>Otevřených úkonů</p>
          </div>
          <div class="icon">
            <i class="ion ion-ios-build"></i>
          </div>
          <a @click="passToLink" href="/#/issues" class="small-box-footer">Seznam úkonů  <i class="fa fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-xs-6">
        <!-- small box -->
        <div class="small-box bg-green">
          <div class="inner">
            <h3>{{stats.sales ? Math.round(stats.sales) : 0}},- Kč</h3>

            <p>Tržby za poslední měsíc s DPH</p>
          </div>
          <div class="icon">
            <i class="ion ion-ios-cash"></i>
          </div>
          <a @click="passToLink" href="/#/sales" class="small-box-footer">Přehled tržeb  <i class="fa fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <!-- ./col -->

      <!-- ./col -->
      <div v-if="!user.cus" class="col-lg-3 col-xs-6">
        <!-- small box -->
        <div class="small-box bg-red">
          <div class="inner">
            <h3>{{stats.plan_sales ? Math.round(stats.plan_sales) : 0}},- Kč</h3>

            <p>Plán tržeb v příštích 30 dnech</p>
          </div>
          <div class="icon">
            <i class="ion ion-ios-trending-up"></i>
          </div>
          <a @click="passToLink" href="#/sales_planned" class="small-box-footer">Přehled plánu tržeb  <i class="fa fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <!-- ./col -->
    </div>

<!--  <div class="row">-->
<!--      <div class="col-md-12">-->
<!--            <div class="box box-primary">-->
<!--                <div class="box-header with-border">-->
<!--                  <i class="ion ion-clipboard"></i>-->
<!--    -->
<!--                  <h3 class="box-title">Nezpracované úkony z rezervačního systému</h3>-->
<!--                </div>-->
<!--                &lt;!&ndash; /.box-header &ndash;&gt;-->
<!--                <div class="box-body">-->
<!--                  -->
<!--                  <div v-if="allTopIssues != ''" class="table-responsive no-padding">-->
<!--                  <table class="table table-hover">-->
<!--                      <thead>-->
<!--                      <tr>-->
<!--                          <th>Úkon</th>-->
<!--                          <th>Auto</th>-->
<!--                          <th v-if="!user.cus">Zákazník</th>-->
<!--                          <th>Od</th>-->
<!--                          <th>Do</th>-->
<!--                      </tr>-->
<!--                      </thead>-->
<!--                      <tbody>-->
<!--                          <tr v-for="issue in allTopIssues" :key="issue.issueid">-->
<!--                              <td><a @click="group.access == 1 ? makeCurrentIssue(issue.issueid) : notPriviledgetAlert()">{{issue.name}}</a>-->
<!--                              <td><a @click="group.access == 1 ? makeCurrentCar(issue.carid) : notPriviledgetAlert()">{{issue.car_name}}</a></td>-->
<!--                              <td v-if="!user.cus"><a @click="group.access == 1 ? makeCurrentCus(issue.customerid) : notPriviledgetAlert()">{{issue.company_name}}</a></td>-->
<!--                              <td>{{getTime(issue.due_from)}}</td>-->
<!--                              <td>{{getTime(issue.due_to)}}</td>-->
<!--                          </tr>-->
<!--                      </tbody>-->
<!--                  </table>    -->
<!--                  </div>-->

<!--                  <div v-else class="callout callout-info">-->
<!--                      <p><i class="icon fa fa-info"></i> Nejsou žádné nové úkony z rezervačního systému.</p>                          -->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; /.box-body &ndash;&gt;-->
<!--              </div>-->
<!--      </div>-->
<!--  </div>   -->

    <div class="row">
      <div class="col-md-12">
            <div class="box box-success">
                <div class="box-header with-border">
                  <i class="ion ion-clipboard"></i>

                  <h3 class="box-title">Upozornění na blížící se STK <span class="badge bg-green" style="margin-left:5px;margin-bottom:2px">{{stkReadyCars.length}}</span></h3>
                </div>
                <div class="box-body">

                  <div v-if="stkReadyCars.length" class="table-responsive no-padding" style="max-height: 400px;overflow-y:scroll">
                    <table class="table table-hover car-table">
                      <thead>
                      <tr>
                          <th>STK do</th>
                          <th>Auto</th>
                          <th v-if="!user.cus">Zákazník</th>
                          <th v-if="!(user.cus && !user.admin)">Řidič</th>
                          <th v-if="!(user.cus && !user.admin)">Odeslat email</th>
                          <th v-if="!(user.cus && !user.admin)">Úkon</th>
                      </tr>
                      </thead>
                      <tbody>
                          <tr v-for="car in stkReadyCars" :key="car.carid">
                              <td>{{getSTKDate(car.stk_expire)}}</td>
                              <td class="cell-title" style="cursor: pointer" @click="makeCurrentCar(car.carid)">
                                <a>{{car.name}}</a><br>
                                <span class="cell-info">SPZ: {{car.licence_plate}}</span><br>
                                <span class="cell-info">VIN: {{car.vin_code}}</span>
                              </td>
                              <td v-if="!user.cus" class="cell-title" style="cursor: pointer" @click="makeCurrentCus(car.customerid)"><a>{{car.company_name}}</a></td>
                              <td v-if="!(user.cus && !user.admin)" class="cell-title" style="cursor: pointer" @click="makeCurrentMnt(car.maintainerid)">
                                <a>{{car.fullname}}</a><br>
                                <span class="cell-info">Tel.: {{getMaintainer(car.maintainerid).phone}}</span><br>
                                <span class="cell-info">Email: {{getMaintainer(car.maintainerid).email}}</span>
                              </td>
                              <td v-if="!(user.cus && !user.admin)">
                                <button v-if="car.emailSent" class="btn btn-default btn-flat"><i class="fa fa-check"></i> Odesláno</button>
                                <button v-else class="btn btn-default btn-flat" @click="sendStkEmail(car)"><i class="fa fa-envelope"></i> Poslat email</button>
                              </td>
                              <td v-if="!(user.cus && !user.admin)">
                                <router-link :to="{ name: 'IssueAdd', params: { carid: car.carid, customerid: car.customerid, maintainerid: car.maintainerid, stk_check: true } }" class="btn btn-default btn-flat"><i class="fa fa-plus"></i> Přidat úkon</router-link>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  </div>

                  <div v-else-if="stkLoaded" class="callout callout-success">
                      <p><i class="icon fa fa-info"></i>  V dohledné době není STK u žádného auta</p>
                  </div>

              </div>
              <div v-if="!stkLoaded" class="overlay">
                <i class="fa fa-refresh fa-spin"></i>
              </div>
        </div>
      </div>
  </div>

</div>
</template>

<script>
const name = 'Dashboard';
import { mapGetters, mapActions } from "vuex";
import api from "@/store/classes/api";
// import 'chart.js';
// import '../assets/js/chart_utils';

export default {
    name,
    data() {
      return {
        stkReadyCars: [],
        stkLoaded: false,
      }
    },
    computed: {
      ...mapGetters(["getAllPages", 'stats', 'allTopIssues', 'user', 'group', 'allCars', 'allMaintainers', 'allIssues', 'allEmails'])
    },
    methods: {
      ...mapActions(["makeActiveTab", "addTab", 'fetchStats', 'fetchTopIssues',
        'makeCurrentIssue', 'makeCurrentCar', 'makeCurrentCus', 'makeCurrentMnt', 'fetchCars', 'fetchMaintainers', 'fetchIssues', 'fetchEmails'
      ]),
      async sendStkEmail(car) {

            //prevent sending again
            if(car.emailSent) return;

            var maintainer = this.getMaintainer(car.maintainerid);

            const mail = {
                sourceid: "stk-"+car.carid,
                from: this.group.email,
                to: maintainer.email,
                subject: "Upozornění na blížící se kontrolu STK",
                html: `Dobrý den, <br>v blízké době bude potřeba vůz ${car.name} (SPZ: ${car.licence_plate}) dostavit k pravidelné kontrole STK. Prosím, ozvěte se s termínem, který by vám vyhovoval.<br>${this.group.company_name}`,
            }

            const res = await api.post("email/send",mail)

            if (res === "OK") {
              this.$alerts.toast('Email byl úspěšně odeslán!', 'success');
              car.emailSent = true;
            } else {
              this.$alerts.toast('Nepodařilo se odeslat email!', 'error');
            }
        },
      getStkIssue(id) {
        var allIssues = this.allIssues;

        if(!allIssues) {
            return false;
        }

        const curr_mth = new Date().getMonth()+1;
        let curr_yrs = new Date().getFullYear();
        let next_month = curr_mth+1;
        let next_year = curr_yrs;
        if(next_month > 12) {
          next_month = 1;
          next_year += 1;
        }

        //filter out non stk issues and check if the issue is present (this and the next month)
        return allIssues.filter( issue => {

          const dt = new Date(issue.due_to*1000);

          return issue.carid == id && issue.stk_check.data[0] == 1 && ((curr_yrs == dt.getFullYear() || next_year == dt.getFullYear()) && (curr_mth == dt.getMonth()+1 || next_month == dt.getMonth()+1));
        })[0];

      },
      getMaintainer(id) {
        var allMaintainers = this.allMaintainers;

        if(!allMaintainers) {
          return null;
        }

        return allMaintainers.filter( item => {
          return item.maintainerid == id;
        })[0];

      },
      getSTKDate(t){
            if (t < 1) {
                return null;
            }
            const dt = new Date(t*1000);

            const day = String(dt.getDate()).padStart(2, '0');
            const month = String(dt.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = dt.getFullYear();

            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate
        },
      getTime(t){
            if (!t){
              return null;
            }
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours().toString();
            let min = dt.getMinutes();
            if (min < 10){
              min = '0' + min.toString();
            }
            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;
        },
        passToLink(event){
          if (this.group.access == 0){
            event.preventDefault()
            // this.$alerts.message('K této agendě nemáte přístup', 'Pro přístup je nutné mít validní předplatné. Zakoupit ho můžete ve svém <a href="/#/personal_info">osobním nastavení</a>.', 'warning');
            this.notPriviledgetAlert();
          }
        },
        notPriviledgetAlert(){
          this.$swal({
              title: 'K této agendě nemáte přístup.',
              icon: 'warning',
              html: 'Pro přístup je nutné mít validní předplatné. Zakoupit ho můžete ve svém <a href="/#/personal_info">osobním nastavení</a>.',
            });
        }
    },
    created() {
        this.fetchStats();
        this.fetchTopIssues();
        const page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(page);
        this.makeActiveTab(page);
        if(!this.user.cus) this.fetchMaintainers();
        this.fetchIssues().then( () => this.fetchEmails().then( () => {

          this.fetchCars().then(() => {

              var allCars = this.allCars;

              if(!allCars) {
                allCars = []
              }

              const curr_mth = new Date().getMonth() + 1;
              let curr_yrs = new Date().getFullYear();

              //filter cars
              this.stkReadyCars = allCars.filter(car => {

                if(!this.getStkIssue(car.carid)) {

                  var t = car.stk_expire;
                  if (t < 1) {
                    return null;
                  }
                  const dt = new Date(t*1000);

                  const mth = dt.getMonth() + 1;
                  const yrs = dt.getFullYear();

                  // Fix for the end of the year
                  let next_month = curr_mth+1
                  let next_year = curr_yrs;
                  if(next_month > 12) {
                    next_month = 1;
                    next_year += 1;
                  }

                  //stk date check (this and the next month)
                  if( ((yrs == curr_yrs || yrs == next_year) && (mth == curr_mth || mth == next_month)) ) {

                    //filter emails by sourceid and sent date
                    var allEmails = this.allEmails;
                    car.emailSent = allEmails.filter(email => {
                      const d = new Date(email.sent * 1000);
                      if(email.sourceid == "stk-"+car.carid && d.getFullYear() == curr_yrs && (d.getMonth()+1 == curr_mth || d.getMonth()+1 == curr_mth+1)) {
                        return email;
                      }
                    }).length > 0;

                    return car;

                  }
                }

              });

              this.stkLoaded = true;
          });

        }));
    },
}
</script>

<style scoped>
a {
    cursor: pointer;
}

.car-table .cell-title {
  font-size: 1.7rem;
}

.car-table .cell-info {
  font-size: 1.3rem;
  white-space: pre;
  color: rgb(156, 156, 156);
}
</style>
